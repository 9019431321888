<!--
* @description:
* @fileName ticketsConfig.vue
* @author lxs
* @date 2022/10/20 14:21
!-->
<template>
  <div>
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <!-- <el-form-item label="门票集合名称">
            <el-input
              class="mr-10"
              placeholder="请输入门票集合名称"
              v-model="state.queryForm.ticketName"
            ></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button icon="Plus" type="primary" @click="handleAdd">
              添加
            </el-button>
          </el-form-item>
          <!-- <el-form-item>
            <el-button icon="Search" type="primary" @click="handleQueryData">
              查询
            </el-button>
          </el-form-item> -->
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="list">
      <el-table-column label="门票集合名称" prop="categoryName" show-overflow-tooltip align="center">
        <template #default="{ row }">
          <div>{{ row.categoryName || '暂无' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="门票原价" prop="originalPrice" show-overflow-tooltip align="center">
        <template #default="{ row }">
          <div>{{ row.originalPrice || '暂无' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="门票现价" prop="sellingPrice" show-overflow-tooltip align="center">
        <template #default="{ row }">
          <div>{{ row.sellingPrice || '暂无' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark" show-overflow-tooltip align="center">
        <template #default="{ row }">
          <div>{{ row.remark ? row.remark : '暂无备注' }}</div>
        </template>
      </el-table-column>

      <el-table-column label="排序" prop="sort" show-overflow-tooltip align="center">
        <template #default="{ row }">
          <div>{{ row.sort ? row.sort : 0 }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip width="350" align="center">
        <template #default="{ row }">
          <el-button type="primary" @click="handleAddTicketList(row)">
            添加
          </el-button>
          <el-button type="primary" @click="handleEdit(row)">编辑</el-button>
          <el-button type="primary" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background v-if="total > 10" :current-page="queryForm.pageNum" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <!-- <edit-tickets ref="refTicketsDialog" @noticeRefresh="getData" /> -->
    <edit-config-drawer :title="title" ref="refEditConfigDrawer" @noticeRefresh="getData"></edit-config-drawer>
    <edit-config-drawer1 ref="refEditConfigDrawer1" :categoryName="queryForm.pageSize" :meetingCode="queryForm.meetingCode" @noticeRefresh="getData"></edit-config-drawer1>

    <el-dialog v-model="dialogVisible" top="25vh" width="15%" append-to-body title="确认要删除吗?">
      <div class="d-flex align-center">
        <el-button class="flex-1 mr-10" type="primary" size="medium" @click="dialogVisible = false">
          取消
        </el-button>
        <el-button class="flex-1 ml-10" size="medium" type="primary" @click="handleConfirmDelete">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getCurrentInstance,
  reactive,
  ref,
  onActivated,
  onMounted,
  onUnmounted,
  defineComponent,
  toRefs,
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Search, Plus } from '@element-plus/icons'
import { getTicketCategory, delTicketCategory } from '@/api/exhibitionManage'
import { useComponent } from './components/index'
import { debounce } from '@/utils/debounce'
import editConfigDrawer from './components/editConfigDrawer.vue'
import editConfigDrawer1 from './components/editConfigDrawer1.vue'
import Bus from '@/utils/bus'
export default defineComponent({
  name: 'ticketsManage',
  components: {
    editConfigDrawer,
    editConfigDrawer1,
  },
  setup(props, { emit }) {
    const router = useRouter() // 获取路由实例
    const route = useRoute() // 获取路由对象
    const { proxy } = getCurrentInstance()
    const state = reactive({
      queryForm: {
        meetingCode: '',
        pageNum: 1,
        pageSize: 10,
        ticketName: '',
        sort: '',
      },
      total: 0,
      list: [],
      layout: 'prev, pager, next',
      editRef: null,
      refEditConfigDrawer: null,
      loading: false,
      filterShelfStatus: {
        0: '待上架',
        1: '已上架',
        2: '已下架',
      },
      filterTicketsRelation: {
        EXHIBITION: '展会类型',
        FORUM: '论坛类型',
        PACKAGE: '套票类型',
        null: '暂无类型',
      },
      refEditConfigDrawer1: null,
      categoryName: '',
      title: '添加门票集合页配置',
      dialogVisible: false,
    })

    // 编辑
    const handleEdit = debounce((row) => {
      console.log('编辑', row)
      state.title = '编辑门票集合页配置'
      let obj = {
        categoryName: row.categoryName,
        originalPrice: row.originalPrice,
        remark: row.remark,
        sellingPrice: row.sellingPrice,
        sort: row.sort,
        id: row.id,
        meetingCode: state.queryForm.meetingCode,
        coverUrl: row.coverUrl,
      }
      state['refEditConfigDrawer'].handleOpen(obj)
    })
    // 初始化数据
    const getData = async () => {
      state.loading = true
      const { data, total } = await getTicketCategory(
        state.queryForm.meetingCode
      )
      state.list = data
      // state.total = data.total
      setTimeout(() => {
        state.loading = false
      }, 500)
    }
    // 添加门票集合页配置
    const handleAdd = debounce(() => {
      state.title = '添加门票集合页配置'
      let obj = {
        meetingCode: state.queryForm.meetingCode,
      }
      console.log(state['refEditConfigDrawer'])
      state['refEditConfigDrawer'].handleOpen(obj)
    })
    // 新增门票集合页里面的门票配置
    const handleAddTicketList = debounce((row) => {
      state.categoryName = row.categoryName
      let obj = {
        ticketCategoryId: row.id,
        ticketCode: '',
        ticketName: row.categoryName,
        ticketDetailList: row.categoryTicketDetailVoList,
      }
      state['refEditConfigDrawer1'].handleOpen(obj)
    })
    // 查询
    const handleQueryData = debounce(() => {
      state.queryForm.pageNum = 1
      getData()
    })
    // 条数
    const handleSizeChange = debounce((pageSize) => {
      state.queryForm.pageSize = pageSize
      getData()
    })
    // 页码
    const handleCurrentChange = debounce((pageNum) => {
      state.queryForm.pageNum = pageNum
      getData()
    })
    let deleteObj = reactive({}) // 删除传递的数据
    const handleConfirmDelete = async () => {
      try {
        let resp = await delTicketCategory(deleteObj)
        proxy.$baseMessage(`删除成功`, 'success', 'vab-hey-message-success')
        getData()
      } catch (err) {
        proxy.$baseMessage(`删除失败`, 'error', 'vab-hey-message-error')
      } finally {
        state.dialogVisible = false
      }
    }
    // 删除
    const handleDelete = async (row) => {
      deleteObj = Object.keys(row).length ? row : {}
      state.dialogVisible = true
    }

    onMounted(() => {
      Bus.$on('noticeRefresh', () => {
        getData()
      })
    })
    onUnmounted(() => {
      Bus.$off('noticeRefresh', () => {})
    })
    // 激活组件
    onActivated(() => {
      state.queryForm.meetingCode = route.query?.meetingCode || ''
      state.queryForm.meetingCode && getData()
    })

    return {
      ...toRefs(state),
      handleDelete,
      handleConfirmDelete,
      handleCurrentChange,
      handleSizeChange,
      handleQueryData,
      handleAddTicketList,
      handleEdit,
      handleAdd,
      getData,
    }
  },
})
</script>
<script setup></script>
